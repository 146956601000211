import React from "react";

import Layout from "../components/Layout";
import Home from "../epics/landing/Home";

const HomePage = () => (
  <Layout>
    {/*<Home />*/}
  </Layout>
);

export default HomePage;
